import React from 'react'
 
const ServiceMobileTags = () => (
 <section className="service-mobile bg-faded py-5">
   <div className="container">
     <div className="row mb-5">
       <div className="col-sm-12 col-md-4">
         <h2 className="text-sm-center text-md-right mb-1">
           <span className="text-muted seo-regular">Tillverkning av mobilappar</span>
           <br />
           <strong>kostnader</strong>
         </h2>
       </div>
       <div className="col-sm-12 col-md-8">
         <div className="row">
           <div className="col-sm-4">
             <strong>Vinster med offshoring</strong>
             <br />
             hög kvalitet till ett lågt pris
           </div>
           <div className="col-sm-4">
             <strong>Time & Materials</strong>
             <br />
             betalning i fördelaktiga trancher
           </div>
           <div className="col-sm-4">
             <strong>GRATIS</strong>
             <br />
             gratis uppskattning av din idé
           </div>
         </div>
       </div>
     </div>
 
     <div className="row">
       <div className="col-sm-12 col-md-4">
         <h2 className="text-sm-center text-md-right">
           <span className="text-muted seo-regular">Tillverkning av</span>
           <br />
           mobilappar
         </h2>
       </div>
       <div className="col-sm-12 col-md-8">
         <ul className="list-unstyled list-inline list-badges lead">
           <li>
             <span className="badge badge-light">Mobil strategi</span>
           </li>
           <li>
             <span className="badge badge-light">Affärsanalys</span>
           </li>
           <li>
             <span className="badge badge-light">
               Bästa design av mobilapp
             </span>
           </li>
           <li>
             <strong className="badge badge-light">
               App Prototyping
             </strong>
           </li>
           <li>
             <span className="badge badge-light">
               Integrering med molnet och API
             </span>
           </li>
           <li>
             <strong className="badge badge-light">
               Tillverkning av mobilappar
             </strong>
           </li>
           <li>
             <span className="badge badge-light">QA testning</span>
           </li>
           <li>
             <span className="badge badge-light">Publicering i App Store</span>
           </li>
           <li>
             <span className="badge badge-light">Hosting & Support</span>
           </li>
         </ul>
         <div className="clearfix" />
       </div>
     </div>
 
     <div className="row">
       <div className="col-sm-12 col-md-4" />
       <div className="col-sm-12 col-md-8" />
     </div>
   </div>
 </section>
)
 
export default ServiceMobileTags