import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/pro-light-svg-icons'
import smoothScroll from 'scroll-to-element'
 
const ServiceMobileCta = () => (
 <section className="service-mobile cto">
   <div className="container">
     <div className="row py-5">
       <div className="col-xs-12 col-sm-4 col-lg-6">
         <div className="lead text-left text-sm-right">
           <h3 className="seo-span seo-strong text-muted">
             Skräddarsydda applikationer
           </h3>
           <br />
           <span className="text-service">
             för iOS och Android
           </span>
           .<br />
           <a
             href="#contact-partial"
             data-form-category="mobileapps"
             className="btn btn-outline-extra1 my-2"
             id="custom-mobile-app-dev-request-btn"
             onClick={event => {
               event.preventDefault()
               smoothScroll('#contact-partial', { duration: 500 })
             }}
           >
             Be om en offert
           </a>
         </div>
       </div>
       <div className="col-xs-12 col-sm-4 col-lg-6">
         <ul className="fa-ul">
           <li>
             <span className="fa-li">
               <FontAwesomeIcon icon={faAngleRight} />
             </span>
             Jobba med{' '}
             <strong className="seo-regular"> Android-appdesigners</strong> och
             med Kotlin-specialister
           </li>
           <li>
             <span className="fa-li">
               <FontAwesomeIcon icon={faAngleRight} />
             </span>
             Anlita <strong className="seo-regular">iOS-programutvecklare</strong>{' '}
             kunniga i Swift
           </li>
           <li>
             <span className="fa-li">
               <FontAwesomeIcon icon={faAngleRight} />
             </span>
             Bygg en Windows-applikation i .NET
           </li>
           <li>
             <span className="fa-li">
               <FontAwesomeIcon icon={faAngleRight} />
             </span>
            Bygg en mobil- webb- eller hybridapp
           </li>
           <li>
             <span className="fa-li">
               <FontAwesomeIcon icon={faAngleRight} />
             </span>
            Få en funktionell och snygg UI/UX
           </li>
         </ul>
       </div>
     </div>
     <hr />
   </div>
 </section>
)
 
export default ServiceMobileCta