import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAndroid,
  faApple,
} from '@fortawesome/fontawesome-free-brands'
import Link from '../../common/Link'

const ServiceMobileOffer = () => (
  <section className="service-mobile">
    <div className="container">
      <div className="row py-5">
        <div className="col-sm-12 col-md-4">
          <h2 className="text-sm-center text-md-right mb-2">
            <span className="text-muted seo-regular">Mobilappar </span>
            <br />
            för storföretagen
          </h2>
        </div>
        <div className="col-sm-12 col-md-8">
          <div className="card-group text-center">
            <div className="card">
              <div className="card-body">
                <h3>Mobile development outsourcing</h3>
                <p className="text-sm text-muted text-italic mt-2">
                För att ta reda på vilken modell som skulle passa just dig, ta en titt i vår{' '}
                  <Link to="/services/poland-software-outsourcing/">
                  outsourcing-guide
                  </Link>
                  .
                </p>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <h3>KONVERTERA DIN WEBBSIDA TILL EN MOBILAPP</h3>
                <p className="text-sm text-muted text-italic mt-2">
                Ta nästa steg och förvandla din webbplats till en lönsam app.
                </p>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <h3>SKRÄDDARSYDDA MOBILAPPAR</h3>
                <p className="text-sm text-muted text-italic mt-2">
                Vi kan skapa vilken app som helst.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row pb-2">
        <div className="col-sm-12 col-md-4">
          <h2 className="text-sm-center text-md-right mb-2">
            <span className="text-muted seo-regular">Design av mobila applikationer </span>
            <br />
            <strong>våra lösningar</strong>
          </h2>
        </div>
        <div className="col-sm-12 col-md-8 pt-2">
          <div className="media feature">
            <div className="feature-icon mr-3">
              <FontAwesomeIcon icon={faApple} size="2x" className="fa-fw" />
            </div>
            <div className="media-body">
              <strong className="seo-h3">DESIGN AV IOS-APPAR</strong>
              <p className="text-muted">
              Anpassade för iPhone, iPad och iWatch, designade för att sticka ut i App Store, för den mest krävande användaren.
              </p>
            </div>
          </div>
          <div className="media feature">
            <div className="feature-icon mr-3">
              <FontAwesomeIcon icon={faAndroid} size="2x" className="fa-fw" />
            </div>
            <div className="media-body">
              <strong className="seo-h3">DESIGN AV ANDROID-APPAR</strong>
              <p className="text-muted">
              Att hålla Android-gränssnittet enhetligt och välfungerande är något som riktar sig till den största gruppen smartphone-användare.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default ServiceMobileOffer
